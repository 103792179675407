import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import Fade from 'react-reveal';
import endpoints from '../constants/endpoints';
import Social from './Social';
import FallbackSpinner from './FallbackSpinner';

const styles = {
  nameStyle: {
    fontSize: '5em',
  },
  inlineChild: {
    display: 'inline-block',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function Home() {
  const [data, setData] = useState(null);
  const [paragraphStyle, setStyle] = useState(styles);

  const updateStyle = () => {
    const vw = window.innerWidth * 0.0095;
    // const vh = window.innerHeight * 0.0095;
    // const responsiveSize = vh + vw;
    // const cresponsiveSize = responsiveSize * 3.5;

    if (vw <= 8.00) {
      setStyle({
        nameStyle: {
          fontSize: '3em',
        },
      });
    } else {
      setStyle({
        nameStyle: {
          fontSize: '5em',
        },
      });
    }
  };
  useEffect(() => {
    fetch(endpoints.home, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => err);

    updateStyle();
    window.addEventListener('resize', updateStyle);
    return () => window.removeEventListener('resiz', updateStyle);
  }, []);

  return data ? (
    <Fade>
      <div style={styles.mainContainer}>
        <h1 style={paragraphStyle.nameStyle}>{data?.name}</h1>
        <div style={{ flexDirection: 'row' }}>
          <h2 style={styles.inlineChild}>I&apos;m&nbsp;</h2>
          <Typewriter
            options={{
              loop: true,
              autoStart: true,
              strings: data?.roles,
            }}
          />
        </div>
        <Social />
      </div>
    </Fade>
  ) : <FallbackSpinner />;
}

export default Home;
